import * as React from 'react';
import { useMemo } from 'react';

import SavingsCalculator from '../../../components/savingsCalculator/SavingsCalculator';
import DebtRepaymentCalculator from '../../../components/debtRepaymentCalculator/DebtRepaymentCalculator';
import RetirementCalculator from '../../../components/retirementCalculator/RetirementCalculator';
import EducationCalculator from '../../../components/educationCalculator/EducationCalculator';
import IncomeTaxCalculator from '../../../components/incomeTaxCalculator/IncomeTaxCalculator';
import BudgetBenchmarkingTool from '../../../components/budgetBenchmarkingTool/BudgetBenchmarkingTool';
import WebComponentsConfig from '../../../components/WebComponentsConfig';

export default function NamibiaSavingsCalculator() {
    console.log('hello world running', process.env.GATSBY_COUNTRY_SELECTOR);

    const onCompleteEvent = (e) => console.log('complete:', e);

    const shouldRender = useMemo(() => {
        try {
            const params = new URLSearchParams(window.location.search);
            return !!params.get('render_demo');
        } catch (e) {
            return false;
        }
    }, []);

    const tableContent = [[]];

    if (!shouldRender) {
        return null;
    }

    return (
        <WebComponentsConfig>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2em',
                    paddingLeft: '7em',
                    paddingRight: '7em',
                    paddingBottom: '7em',
                    paddingTop: '3em',
                    backgroundImage: 'linear-gradient(270deg, rgb(96, 184, 72) 1.64%, rgb(0, 150, 119) 98.36%)',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Savings Calculator</h3>

                    <SavingsCalculator
                        currencyCode="N$"
                        tableContent={{}}
                        isInContainer={{}}
                        onCompleteEvent={onCompleteEvent}
                        slot={{}}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Debt Repayment Calculator</h3>

                    <DebtRepaymentCalculator
                        isInContainer={{}}
                        currencyCode="N$"
                        tableContent={{}}
                        onCompleteEvent={onCompleteEvent}
                        slot={{}}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Retirement Calculator</h3>

                    <RetirementCalculator
                        twoColumnImageLeftContent={false}
                        tableContent={{}} // product_offering_two && product_offering_two[0]} slot="application-content"
                        isInContainer={{}}
                        onCompleteEvent={onCompleteEvent}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Education Calculator</h3>

                    <EducationCalculator
                        uid={{}}
                        twoColumnImageLeftContent={{}}
                        productTableContent={false}
                        slot="application-content"
                        isInContainer={{}}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Income Tax Calculator</h3>

                    <IncomeTaxCalculator
                        uid={{}}
                        twoColumnImageLeftContent={{}}
                        productTableContent={false}
                        slot="application-content"
                        isInContainer={{}}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1em',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        paddingTop: '1em',
                        paddingBottom: '2em',
                        backgroundColor: 'rgb(255, 255, 255, 0.95)',
                        borderRadius: '5px',
                        boxShadow:
                            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    }}
                >
                    <h3>Budget Benchmarking Tool</h3>

                    <BudgetBenchmarkingTool
                        slot="application-content"
                        isInContainer={{}}
                        onCompleteEvent={onCompleteEvent}
                    />
                </div>
            </div>
        </WebComponentsConfig>
    );
}
